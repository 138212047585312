<template>
    <div class="up_content_inner up_manage_site">
        <div class="up_main_container">
            <div class="up_page_header">
                <h2 class="up_page_title">Manage Sites</h2>
            </div>
            <div class="up_inner_container">
                <!--- Show Website Table Start --->
                <DataTable class="p-datatable p-mb-2" :paginator="true" stripedRows :value="allWebsiteData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="" :rowHover="true" :loading="allWebsiteData.loading" responsiveLayout="scroll" removableSort :filters="filters" :dataKey="uuid">
                    <template #loading>
                        Loading website data. Please wait.
                    </template>
                    <template #header>
                        <div class="p-d-flex p-jc-end">
                            <div class="p-d-flex p-align-center">
                                <span class="p-input-icon-left sup_search_box">
                                    <i class="pi pi-search" />
                                    <InputText v-model="allWebsiteData.search" placeholder="Search Website" @keyup.enter="fetchAllWebsite('search')"/>
                                </span>
                                <Button v-if="allWebsiteData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllWebsite('resetFilters')"/>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No Website found.
                    </template>
                    <Column field="siteName" header="Website" headerStyle="width: 25rem" :sortable="true">
                        <template #body="slotProps">
                            <div class="p-d-flex">
                                <Button v-if="slotProps.data.License !== null && slotProps.data.License.status === 'auth'" type="button" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-outlined p-button-gray p-mr-2 p-f whiteOpendialog" @click="viewWebsiteById(slotProps.data, 'viewWebsite')" v-tooltip="'View More'" />
                                <div :class="['up_website_info', slotProps.data.License === null || slotProps.data.License.status === 'ban' ? 'up_extra_padding' : '']">
                                    <span class="sup_site_info p-d-block">{{slotProps.data.siteName}}</span>
                                    <a :href="'https://' + [slotProps.data.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.siteUrl}}</a>
                                    <img v-if="slotProps.data.serviceFor === 'service'" class="up_additional_service" v-tooltip="'Additional Service'" :src="imageUrlFrontEnd + '/dist/img/additional-service-small.png'" title="">
                                </div>
                                 <span :class="['up_item_status ', slotProps.data.License === null ? 'up_item_status_warning' : slotProps.data.License.status === 'auth' ?'up_item_status_success' : 'up_item_status_danger']">{{slotProps.data.License === null ? 'Incomplete' : slotProps.data.License.status === 'auth' ? 'Active' : 'Ban'}}</span>
                            </div>
                        </template>
                    </Column>

                    <Column field="Websitehit" header="Pageviews" style="text-align: center">
                        <template #body="slotProps">
                            {{slotProps.data.Websitehit === null ? 0 : slotProps.data.Websitehit.currentHits}}
                        </template>
                    </Column>

                    <!-- <Column field="status" header="Status" style="text-align: center" headerStyle="width: 15rem">
                        <template #body="slotProps">
                            <span :class="['up_item_status ', slotProps.data.License === null ? 'up_item_status_warning' : slotProps.data.License.status === 'auth' ?'up_item_status_success' : 'up_item_status_danger']">{{slotProps.data.License === null ? 'Incomplete' : slotProps.data.License.status === 'auth' ? 'Active' : 'Ban'}}</span>
                        </template>
                    </Column> -->

                    <Column field="Servicetype" header="Services" headerStyle="width: 12rem" >
                        <template #body="slotProps">
                        <div class="siteServiceAdd">
                            <Button v-if="slotProps.data.License !== null ? slotProps.data.License.status !== 'ban' ? slotProps.data.serviceType === 'ada_cookies_privacyForm' || slotProps.data.serviceType === 'ada_privacyForm_cookies' || slotProps.data.serviceType === 'cookies_privacyForm' || slotProps.data.serviceType === 'privacyForm_cookies' ? false : true : false : false" icon="pi pi-plus" class="p-button-primary up_service_add" v-tooltip="'Add Service'" @click="$router.push({
                                name: 'CustomerEditWebsite',
                                params: {
                                    websiteData: JSON.stringify({
                                        uuid: slotProps.data.uuid,
                                        services: slotProps.data.Servicetype,
                                        payment: true
                                    })
                                } 
                            })" />
                        </div>
                            <div v-if="slotProps.data.serviceFor === 'ada' || slotProps.data.serviceFor === 'both'"  class="up_ada_widget">
                                    <div class="up_adawidget_value" >
                                        <span class="service_action_content"><span class="up_service_item_action">ADA</span></span>
                                    </div>
                                    
                                </div>
                            <div v-if="slotProps.data.Servicetype" class="up_services">
                                <div v-for="service of slotProps.data.Servicetype" :key="service.Servicetype.uuid" :class="['up_service_item p-mr-2', 'up_service_' + service.Servicetype.serviceName.toLowerCase()]">
                                    <div class="up_service_item_value" v-tooltip="service.Servicetype.serviceName === 'privacyForm' ? 'CCPA Process' : service.Servicetype.serviceName + ' Service'">
                                        <span class="service_action_content">{{service.Servicetype.serviceName === 'privacyForm' ? 'CCPA Process': 'Cookie Consent'}}</span>
                                        <span class="up_service_item_action" @click="deleteService(slotProps.data, service.Servicetype.serviceName)"><i class="pi pi-times" v-tooltip.right="'Remove Service'"></i></span>
                                    </div>
                                    
                                </div>
                            </div>
                        </template>
                    </Column>

                    <Column field="lightHouseScore" header="Lighthouse" :sortable="true" style="text-align: center">
                        <template #body="slotProps">
                         <div v-if="slotProps.data.lightHouseScore === null && slotProps.data.status === true" >
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            <h6>Generating Report</h6>
                        </div>
                        <div v-if="slotProps.data.lightHouseScore" class="light_house_control">
                            <div v-if="slotProps.data.lightHouseScore !== 'invalid'">
                            
                                <button :style="{'display': rescanSelect.id === slotProps.data.siteUrl && rescanSelect.scan === true ? 'none' : 'inline' }" :id="slotProps.data.siteUrl"  @click="reGenerateLightHouse(slotProps.data.siteUrl)"  >
                                    <Knob v-model="slotProps.data.lightHouseScore" readonly valueTemplate="{value}" :strokeWidth="15" :size="50" 
                                    :valueColor="slotProps.data.lightHouseScore > 0 && slotProps.data.lightHouseScore < 50 ? '#d73925' : slotProps.data.lightHouseScore > 49 && slotProps.data.lightHouseScore < 90 ? '#f39c12' : '#77c887'"
                                    :rangeColor="slotProps.data.lightHouseScore > 0 && slotProps.data.lightHouseScore < 50 ? '#d73925' : 
                                    slotProps.data.lightHouseScore > 49 && slotProps.data.lightHouseScore < 90 ? '#f39c12' : '#77c887'" 
                                    animation v-tooltip="'Lighthouse Accessibility Score'" />
                                </button>
                                <div :id="slotProps.data.siteUrl" :style="{'display': rescanSelect.id === slotProps.data.siteUrl && rescanSelect.scan === true ? 'inline' : 'none' }">
                                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                                    <h6>Generating Report</h6>
                                </div>
                            </div>
                        </div>
                        <div v-if="slotProps.data.status === false && slotProps.data.lightHouseScore === null" >
                            <h6>--</h6>
                        </div>
                        <div v-if="slotProps.data.lightHouseHtmlFile !== null">
                            <div class="siteServiceAdd">
                                <a :href="slotProps.data.lightHouseHtmlFile" download  class="download_primary_btn up_service_add"><i class="pi pi-download" style="font-size: 2rem"></i></a>
                            </div>
                            
                        </div>
                        </template>
                    </Column>

                    <Column field="Websiterealtimescanreport" header="Violations" :sortable="true" style="text-align: center"  headerStyle="width: 20rem">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.Websiterealtimescanreport === null && slotProps.data.status === true">
                            <button class="rltScanBtn" @click="confimationModal(slotProps.data.siteUrl)"><i class="pi pi-plus" /> Add Scan</button>
                        </div>
                        <div v-if="slotProps.data.Websiterealtimescanreport !== null" class="light_house_control" 
                        @click="$router.push({ name: 'CustomerViolationReport',
                        params :{
                            uuid : slotProps.data.Websiterealtimescanreport.uuid
                        }
                        })">
                        <div class="scan_violations">
                            <div class="scan_violotion_box">
                                <Knob v-model="slotProps.data.Websiterealtimescanreport.alerts" readonly valueTemplate="{value}%" :strokeWidth="15" :size="50" valueColor="#d73925" rangeColor="#d7392533" animation v-tooltip="'Alerts'" />
                            </div>
                            <div class="scan_violotion_box">
                                <Knob v-model="slotProps.data.Websiterealtimescanreport.contrastErrors" readonly valueTemplate="{value}%" :strokeWidth="15" :size="50" 
                                 valueColor="#3366cc" rangeColor="#3366cc33" animation v-tooltip="'Contrast Errors'" />
                            </div>
                            <div class="scan_violotion_box">
                                <Knob v-model="slotProps.data.Websiterealtimescanreport.errors" readonly valueTemplate="{value}%" :strokeWidth="15" :size="50" valueColor="#f39c12" rangeColor="#f39c1233" animation v-tooltip="'Error'" />
                            </div>                            
                        </div>
                            
                            <!-- <Knob v-if="slotProps.data.Websiterealtimescanreport !== null" v-model="slotProps.data.Websiterealtimescanreport.totalViolationCount" readonly /> -->
                        </div>
                    </template>
                    </Column>

                    <Column field="createdAt" header="Created On" :sortable="true" headerStyle="width: 15rem" style="text-align: center">
                        <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt)}}
                        </template>
                    </Column>

                    <Column field="action" header="Actions" style="text-align: center">
                        <template #body="slotProps">
                            
                            <div class="manage_sites_action">
                                <Button v-if="slotProps.data.License === null" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-help" v-tooltip="'Edit Website'" @click="$router.push({ 
                                name: 'CustomerEditWebsite',
                                params: {
                                    websiteData: JSON.stringify({
                                        name: slotProps.data.siteName, 
                                        url: slotProps.data.siteUrl, 
                                        type: slotProps.data.serviceType, 
                                        services : 'incompleteEdit',
                                        serviceFor: slotProps.data.serviceFor, 
                                        payment: false
                                    })
                                    }
                                })" 
                            />
                                <Button v-if="slotProps.data.License !== null && slotProps.data.License.status !== 'ban'" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-warning" v-tooltip="'Cancel Website'" @click="confirmModal(slotProps.data, 'cancel')" />
                                <!-- <Button v-if="slotProps.data.License !== null && slotProps.data.License.status !== 'ban' && slotProps.data.serviceFor !== 'ada' && slotProps.data.serviceFor !== 'both'" type="button" icon="pi pi-globe" class="p-button-sm p-button-rounded p-button-help" v-tooltip="'Add ADA Widget'" /> -->
                                <Button type="button" icon="pi pi-trash" class="p-button-sm p-button-rounded p-button-danger" v-tooltip="'Delete Website'" @click="confirmModal(slotProps.data, 'delete')" />
                                <Button v-if="slotProps.data.License !== null && slotProps.data.License.status !== 'ban'" icon="pi pi-copy" class="p-button-secondary p-button-rounded p-button-secondary"  v-tooltip.left="'Copy Installation Script'" @click="copyScript($event)" />
                                <InputText class="up_copy_license" v-if="slotProps.data.License !== null && slotProps.data.License.status === 'auth'" type="text" :value="scriptValue.firstPart + slotProps.data.License.licenseKey + scriptValue.LastPart + scriptValue.LastEndPart" readonly />
                                <Button v-if="slotProps.data.License !== null && slotProps.data.License.status !== 'ban'" icon="pi pi-download" class="p-button-plain p-button-rounded p-component p-button-sm" v-tooltip.left="'Download Installation Manual'" @click="downloadScript(slotProps.data)" />
                                </div>
                            
                        </template>
                    </Column>
                </DataTable>
                <!--- Show Website Table End --->
            </div>
        </div>
    </div>
        <!--- View Website Details Modal --->
        <Dialog v-model:visible="websiteModal.viewWebsite" contentClass="up_custom_content" class="website_details_wrapper" :style="{width: '1230px'}" :modal="true">
            <template #header>
                <span class="p-dialog-title">
                    Overview - {{websiteModal.viewWebsiteData.siteUrl}}
                    
                    <span class="up_site_status">
                        <span :class="['up_item_status', websiteModal.viewWebsiteData.status ? 'up_item_status_success' : 'up_item_status_warning']">{{websiteModal.viewWebsiteData.status ? 'Active' : 'Incomplete'}}</span>
                    </span>
                </span>
            </template>
            <div class="up_website_basics p-d-flex p-flex-wrap p-mb-3">
                <div class="up_website_info_item">
                    <div class="up_website_info_title">Created On:</div>
                    <div class="up_website_info_value">{{usFormatDate(websiteModal.viewWebsiteData.createdAt)}}</div>
                </div>
                <div class="up_website_info_item">
                    <div class="up_website_info_title">Copy Script:</div>
                    <div class="up_website_info_value" @click="copyScript($event)"><i class="pi pi-copy"></i></div>
                    <InputText class="up_copy_license" v-if="websiteModal.viewWebsiteData.License !== null && websiteModal.viewWebsiteData.License.status === 'auth'" type="text" :value="scriptValue.firstPart + websiteModal.viewWebsiteData.License.licenseKey + scriptValue.LastPart + scriptValue.LastEndPart" readonly />
                </div>
                <div class="up_website_info_item">
                    <div class="up_website_info_title">Total Pageviews:</div>
                    <div class="up_website_info_value">{{websiteModal.viewWebsiteData.Websitehit === null ? 0 : websiteModal.viewWebsiteData.Websitehit.totalHits}}</div>
                </div>
                <div class="up_website_info_item" v-if="websiteModal.viewWebsiteData.Payment.couponCode !== null">
                    <div class="up_website_info_title">Coupon:</div>
                    <div class="up_website_info_value">{{websiteModal.viewWebsiteData.Payment.couponCode === null ? 'NA' : websiteModal.viewWebsiteData.Payment.couponCode}} | {{websiteModal.viewWebsiteData.Payment.couponType === null ? 'NA' : websiteModal.viewWebsiteData.Payment.couponType === 'percentage' ? websiteModal.viewWebsiteData.Payment.couponDiscount + '%' : '-$' + websiteModal.viewWebsiteData.Payment.couponDiscount }}</div>
                </div>
                <div class="up_website_info_item">
                    <div class="up_website_info_title">Services:</div>
                    <template v-if="websiteModal.viewWebsiteData.servicetype !== null">
                        <div v-for="service of websiteModal.serviceType" :key="service" :class="['up_service_item p-mr-2', 'up_service_' + service.toLowerCase()]">
                            <span class="up_service_item_value">{{service === 'privacyForm' ? 'CCPA Process' : service === 'cookies' ? 'Cookie Consent' :  service.toUpperCase()}}</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="up_website_detail_info">
                <TabView @click="fetchScanReport()">
                    <TabPanel header="Pageviews">
                        <div class="up_website_pageviews p-d-flex">
                            <div class="p-col-12">
                                <div class="up_website_pageviews_card">
                                    <div class="overmonth_heading">
                                        <h4>Month Over Month Pageviews</h4>
                                        <!-- <span>
                                            <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Select Month" />
                                        </span> -->
                                    </div>
                                    <div class="month_wise_cart_view">
                                        <span v-if="!hitsGraphData.available" >No data available yet</span>
                                        <Chart v-else type="bar" :data="hitsGraphData" :options="basicOptions" />
                                    </div>
                                    
                                </div>

                                
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header="Invoices">
                        <div class="up_invoice">                            
                            <!--- Show Invoice Table Start --->
                            <DataTable class="p-datatable p-mb-2 invoiceDatatable" stripedRows :value="allWebsiteData.invoice" :rowHover="true" :loading="allWebsiteData.loading" responsiveLayout="scroll" removableSort>
                                <template #loading>
                                    Loading website data. Please wait.
                                </template>
                                <template #header>
                                    <!-- <div class="p-d-flex p-jc-end">
                                        <div class="p-d-flex p-align-center">
                                            <span class="p-input-icon-left sup_search_box">
                                                <i class="pi pi-search" />
                                                <InputText v-model="allWebsiteData.search" placeholder="Search Website" @keyup.enter="fetchAllWebsite('search')"/>
                                            </span>
                                            <Button v-if="allWebsiteData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllWebsite('resetFilters')"/>
                                        </div>
                                    </div> -->
                                </template>
                                <template #empty class="noDataAvailable">
                                    No data available.
                                </template>
                                <Column field="invoiceNumber" header="Invoice #" :sortable="true">
                                    <template #body="slotProps">
                                        {{slotProps.data.invoiceNumber}}
                                    </template>
                                </Column>
                                <Column field="orderType" header="Type" :sortable="true" />
                                <Column field="chargedAmount" header="Rate" :sortable="true">
                                    <template #body="slotProps">
                                        {{'$' + slotProps.data.chargedAmount}}
                                    </template>
                                </Column>
                                <Column field="billingPeriod" header="Billing Period" :sortable="true">
                                    <template #body="slotProps">
                                        {{usFormatDate((slotProps.data.billingPeriod.split(" "))[0]) + " to " + usFormatDate((slotProps.data.billingPeriod.split(" "))[1])}}
                                    </template>
                                </Column>
                                <Column field="status" header="Status" :sortable="true">
                                    <template #body="slotProps">
                                        <span :class="['up_item_status ', slotProps.data.orderStatus === 'paid' ? 'up_item_status_success' : 'up_item_status_danger']">{{slotProps.data.orderStatus === 'paid' ? 'Paid' : 'Failed'}}</span>
                                    </template>
                                </Column>
                                <column field="Actions" header="Actions">
                                    <template #body="slotProps">
                                        <Button v-if="slotProps.data !== null" icon="pi pi-eye" class="p-button-secondary p-button-rounded p-button-secondary"  v-tooltip.left="'View Invoice'" 
                                        @click="$router.push({
                                            name : 'CustomerInvoiceDetails',
                                            params: {
                                                invoiceData:JSON.stringify(slotProps.data)
                                            }
                                        })" />
                                    </template>
                                </column>
                            </DataTable>
                            <!--- Show Invoice Table End --->
                        </div>
                    </TabPanel>
                    <TabPanel header="Branding">
                        <div class="up_website_branding p-d-flex">
                            <div class="noDataFound" v-if="websiteModal.viewWebsiteData.Websitebranding === null">
                                <Button  type="button" class="rltScanBtn" @click="$router.push({ name: 'CustomerAddBranding', params: { Website: JSON.stringify({
                                                Website: {
                                                    uuid: websiteModal.viewWebsiteData.uuid,
                                                    siteName: websiteModal.viewWebsiteData.siteName,
                                                    siteUrl: websiteModal.viewWebsiteData.siteUrl,
                                                    serviceType: websiteModal.viewWebsiteData.serviceType,
                                                    serviceFor: websiteModal.viewWebsiteData.serviceFor
                                                },
                                                // color: branding.Websitebranding.color,
                                                // createdAt: branding.Websitebranding.createdAt,
                                                // customlogoUrl: branding.Websitebranding.customlogoUrl,
                                                // deletedAt: branding.Websitebranding.deletedAt,
                                                // enableGdpr: branding.Websitebranding.enableGdpr,
                                                // enableSiteTracking: branding.Websitebranding.enableSiteTracking,
                                                // gdprText: branding.Websitebranding.gdprText,
                                                // icon: branding.Websitebranding.icon,
                                                // logo: branding.Websitebranding.logo,
                                                // status: branding.Websitebranding.status,
                                                // updatedAt: branding.Websitebranding.updatedAt,
                                                // uuid: branding.Websitebranding.uuid,
                                                // widgetMode: branding.Websitebranding.widgetMode,
                                                // widgetPosition: branding.Websitebranding.widgetPosition
                                            }) } })" ><i class="pi pi-plus" /> Add Branding</button>
                                <!-- <Button  type="button" class="rltScanBtn" @click="addBrandingToExisting()" ><i class="pi pi-plus" /> Add Branding</button> -->
                                <!-- <button v-tooltip="'Realtime Scan'" class="rltScanBtn" @click="$router.push({ name: 'CustomerAccessibilityScan'})"><i class="pi pi-search" /> Add Scan</button> -->
                            </div>
                                
                            

                            <div class="p-col-12" v-if="websiteModal.viewWebsiteData.Websitebranding !== null" >
                                <div class="sup_dialog_content sup_Branding_info" v-for="branding in websiteModal.brandingData" :key="branding.uuid">
                                <div class="edit_dialog_branding">
                                    <h4>Manage Branding</h4>
                                     <Button v-if="branding.Websitebranding.deletedAt === null" type="button" icon="pi pi-cog" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Manage Branding'" @click="$router.push({ 
                                        name: 'CustomerEditBranding', 
                                        params: {
                                            brandingData: JSON.stringify({data: {
                                                Website: {
                                                    uuid: branding.uuid,
                                                    siteName: branding.siteName,
                                                    siteUrl: branding.siteUrl,
                                                    serviceType: branding.serviceType,
                                                    serviceFor: branding.serviceFor
                                                },
                                                color: branding.Websitebranding.color,
                                                createdAt: branding.Websitebranding.createdAt,
                                                customlogoUrl: branding.Websitebranding.customlogoUrl,
                                                deletedAt: branding.Websitebranding.deletedAt,
                                                enableGdpr: branding.Websitebranding.enableGdpr,
                                                enableSiteTracking: branding.Websitebranding.enableSiteTracking,
                                                gdprText: branding.Websitebranding.gdprText,
                                                icon: branding.Websitebranding.icon,
                                                logo: branding.Websitebranding.logo,
                                                status: branding.Websitebranding.status,
                                                updatedAt: branding.Websitebranding.updatedAt,
                                                uuid: branding.Websitebranding.uuid,
                                                widgetMode: branding.Websitebranding.widgetMode,
                                                widgetPosition: branding.Websitebranding.widgetPosition
                                            }}),
                                        } 
                                    })" />
                                </div>
                                   
                                    <div class="sup_Branding_info_section">
                                        <div class="sup_section_title"><i class="pi pi-globe"></i>Site info</div>
                                        <ul>
                                            <li><strong>Name:</strong> {{branding.siteName.charAt(0).toUpperCase() + branding.siteName.slice(1)}}</li>
                                            <li><strong>URL:</strong> <a target="_blank" :href="'http://' + branding.siteUrl">{{branding.siteUrl}}</a></li>
                                            <li><strong>Service:</strong>
                                            <template v-if="branding.serviceType !== null">
                                            <div v-for="service of websiteModal.serviceType" :key="service" :class="['up_service_item p-mr-2', 'up_service_' + service.toLowerCase()]">
                                                <span class="up_service_item_value">{{service === 'privacyForm' ? 'Privacy Form' : service}}</span>
                                            </div>
                                            </template>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="p_card_wrapper">
                                        <div class="sup_section_title"><h4>Customizations</h4></div>
                                         <div v-if="branding.Websitebranding !== null" class="sup_Branding_info_section">
                                        
                                        <ul>
                                            <li><strong>Widget Mode:</strong> {{camelToWords(branding.Websitebranding.widgetMode)}}</li>
                                            <li><strong>Widget Position:</strong> {{camelToWords(branding.Websitebranding.widgetPosition)}}</li>
                                            <li><strong>Logo:</strong>
                                                <img class="up_widget_logo" :src="branding.Websitebranding.logo === 'logo-white.png' ? iconUrl + 'logo-white.png' : branding.Websitebranding.logo" :alt="branding.Websitebranding.logo" />
                                            </li>
                                            <li><strong>GDPR Text:</strong> 
                                                <!-- <div v-html="Object.values(JSON.parse(Object.assign({}, branding).WebsiteBranding.gdprText))"></div> -->
                                                <div v-html="websiteModal.gdprData"></div>
                                            </li>
                                            <li><strong>Site Tracking:</strong> {{branding.Websitebranding.enableSiteTracking === true ? 'Enabled' : 'Disabled'}}</li>
                                            <li><strong>Logo URL:</strong>{{branding.Websitebranding.customLogoUrl ? branding.Websitebranding.customLogoUrl : 'NA'}}</li>
                                            <li><strong>Color :</strong>
                                            <span v-if="branding.Websitebranding.color" class="up_widget_color" :style="'background-color:' + branding.Websitebranding.color"></span></li>
                                        </ul>
                                    </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <!-- <TabPanel header="Health Meter">
                        <div class="p-grid">
                            <div class="p-col-7">
                                <h4>Activity Info For - October 2021</h4>
                                <div class="up_meter_list">
                                    <div class="up_meter_list_box">
                                    <div class="up_meter_list_box_inner">
                                        <figure><img :src="imageUrlFrontEnd + 'dist/img/visitors.png'" alt="Visitors"></figure>
                                        <span class="up_count_number" id="unquieNoSpan"><span id="number">100</span>%</span>
                                        <strong id="unquieNoSpanCount"><span id="number">(6)</span></strong>
                                        <p>Unique Visitors</p>
                                    </div>
                                    </div>
                                    <div class="up_meter_list_box">
                                    <div class="up_meter_list_box_inner">
                                        <figure><img :src="imageUrlFrontEnd + 'dist/img/cookies.png'" alt="Cookies"></figure>
                                        <span class="up_count_number" id="acceptAllCookieSpan"><span id="number">33.33</span>%</span>
                                        <strong id="acceptAllCookieSpanCount"><span id="number">(2)</span></strong>
                                        <p>Accepted all cookies</p>
                                    </div>
                                    </div>
                                    <div class="up_meter_list_box">
                                    <div class="up_meter_list_box_inner">
                                        <figure><img :src="imageUrlFrontEnd + 'dist/img/cookie-rejected.png'" alt="Cookies"></figure>
                                        <span class="up_count_number" id="declinedAllCookieSpan"><span id="number">50.00</span>%</span>
                                        <strong id="declinedAllCookieSpanCount"><span id="number">(3)</span></strong>
                                        <p>Declined all cookies</p>
                                    </div>
                                    </div>
                                    <div class="up_meter_list_box">
                                    <div class="up_meter_list_box_inner">
                                        <figure><img :src="imageUrlFrontEnd + 'dist/img/no-ads.png'" alt="Cookies"></figure>
                                        <span class="up_count_number" id="rejectMarketingCookieSpan"><span id="number">0.00</span>%</span>
                                        <strong id="rejectMarketingCookieSpanCount"><span id="number">(0)</span></strong>
                                        <p>Rejected marketing and advertising</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-5">
                                <h4 class="p-text-center">Device Type</h4>
                            </div>
                        </div>
                    </TabPanel> -->
                    <TabPanel header="Accessibility Report">
                        <div class="up_website_pageviews p-d-flex">
                            <div class="p-col-12">
                                <div v-if="progressReport.uuid === null">
                                    <button v-tooltip="'Realtime Scan'" class="rltScanBtn" @click="$router.push({ name: 'CustomerAccessibilityScan'})"><i class="pi pi-search" /> Add Scan</button>
                                </div>
                                    <div v-else class="up_main_container accessibility_report_wrapper">
                                        <div class="up_page_header p-mb-4 p-d-flex p-jc-between">
                                            <h4 class="up_page_title">View Accesibility Scanning</h4>
                                            <div class="p-d-flex">
                                                <Button type="button" :icon="['pi', progressReport.loadingDownload ? 'pi-spin pi-spinner' : 'pi-file-pdf']" label="Download PDF" class="p-button-primary p-mr-2" @click="downloadReport()" :disabled="progressReport.loadingDownload" />
                                                <Button type="button" icon="pi pi-eye" :label="`View (${websiteModal.viewWebsiteData.Websiterealtimescanreport.totalViolationCount}) violations`" class="p-button-danger p-mr-2" @click="$router.push({
                                                    name: 'CustomerViolationReport',
                                                    params: {
                                                        uuid: progressReport.uuid
                                                    },
                                                })" />
                                            </div>
                                        </div>
                                        <div class="p_card_wrapper">
                                            <div v-if="progressReport.loading" class="loading">Loading Reports...</div>
                                            <div v-if="!progressReport.loading" class="p-grid p-formgrid p-text-center up_scan_typs">
                                                <div class="p-field p-col-6 p-sm-4 p-md-4">
                                                    <Knob v-model="progressReport.error" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#d73925" rangeColor="#d7392533" animation />
                                                    <h5>Errors</h5>
                                                </div>
                                                <div class="p-field p-col-6 p-sm-4 p-md-4">
                                                    <Knob v-model="progressReport.contrastErrors" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#3366cc" rangeColor="#3366cc33" />
                                                    <h5>Contrast Errors</h5>
                                                </div>
                                                <div class="p-field p-col-6 p-sm-4 p-md-4">
                                                    <Knob v-model="progressReport.alerts" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#f39c12" rangeColor="#f39c1233" />
                                                    <h5>Alerts</h5>
                                                </div>
                                                <div class="p-field p-col-6 p-sm-4 p-md-4">
                                                    <Knob v-model="progressReport.features" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#008d4c" rangeColor="#008d4c33" :max="500" />
                                                    <h5>Features</h5>
                                                </div>
                                                <div class="p-field p-col-6 p-sm-4 p-md-4">
                                                    <Knob v-model="progressReport.structuralElements" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#77c887" rangeColor="#77c88733" :max="500" />
                                                    <h5>Structural Elements</h5>
                                                </div>
                                                <div class="p-field p-col-6 p-sm-4 p-md-4">
                                                    <Knob v-model="progressReport.aria" readonly  valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#46c1b0" rangeColor="#46c1b033" />
                                                    <h5>Aria</h5>
                                                </div>
                                            </div>
                                        </div>
                                        
                                   </div>
                            </div>
                        </div>
                    </TabPanel> 
                    <TabPanel header="Monitoring">
                        <div class="up_website_pageviews p-d-flex">
                            <div class="p-col-12">
                                <div v-if="websiteModal.viewWebsiteData.Websitemonitoring === null">
                                    <Button type="button" label="Start Monitoring" class="p-button-info p-mr-2" @click="$router.push({
                                    name: 'CustomerMonitoring'})" />
                                </div>
                                <div class="bottom_grid_wrapper siteOverview_monitor" v-if="websiteModal.viewWebsiteData.Websitemonitoring !== null">
                                <div class="classic_grid grid_left">
                                    <div class="monitor_grid_wrapper">
                                    <ul>
                                        <li  v-for="(val, i) in websiteModal.viewWebsiteData.Websitemonitoring" :key="i">
                                            <div class="others_conditions">
                                                <!-- <div class="monitor_en_dis">
                                                    <InputSwitch v-model="checked" />
                                                </div> -->
                                            <!-- <button id="rescan-ssl-certificate-validity" class="run-test do-rescan-btn monitor_rescan"
                                                        data-servicelogid="99" data-websiteid="219" data-serviceid="1"
                                                        data-toggle="tooltip" data-placement="left" title="" style="display: flex"
                                                        data-original-title="Rescan" @click="confimationModal(val)" v-tooltip="'Reload data'">
                                                        <img src="https://console.staging.truabilities.com/frontend_assets/dist/img/replay-blue.png"
                                                            alt="Rescan image">
                                                </button> -->
                                            </div>
                                           
                                            <div :id="'monitor_box' + i"  @click="openBox(val, i)" class="monitor_inner_box" :class="seletedItem._id === 'monitor_box'+i && seletedItem.clicked === true ? 'selected': ''" >    
                                            <div class="monitor_box">
                                                <figure>
                                                           
                                                                <img :src="val.serviceName === 'SSL Certificate Validity' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/SSL.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=TsyaIvLDF%2BdzcOHDHFaOCiS9eVo%3D' :
                                        val.serviceName === 'Sucuri Site Check' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/sucuri-site-check.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=cUJXgLwyaeINCmaozRLnNJXlAQk%3D' : val.serviceName === 'Domain Blacklist' ?
                                            'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/domain-blacklisting.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=8hidTZGN6gA9SL14najz%2FxDKnEI%3D' : val.serviceName === 'Google Safe Browsing' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/google-safe-browsing.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=8gz8hzbbXhqekqQbUaDAERsKqow%3D' :
                                                val.serviceName === 'Page Speed' ? 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/Uptime.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=NjewuDcX7keu7QZ9kaLAhgm5avw%3D' : 'https://truabilities-prod.s3.us-west-1.amazonaws.com/console/yandex-safe-browsing.png?AWSAccessKeyId=AKIAZJ4VKLIY3CG2OX4B&Expires=1978806542&Signature=Ujyc4ZXQTn%2FgR0k8pd10TJt4I48%3D'"
                                                    alt="">
                                                        </figure>
                                                    
                                                </div>
                                                <h4>{{ val.serviceName }}</h4>
                                                <p>{{ dateDiff(formatDate(val.createdAt), new Date()) }} </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                                <div class="classic_grid grid_right"  v-if="monitorData.isShow">
                                    <div class="up_moniter_preview_card">
                                        <span class="p-dialog-title monitor_branding_title">
                                            <h3>{{ monitorData.serviceName }}<span class="status">
                                                <i class="fa fa-check-circle"></i>{{ monitorData.isQualified === true ?
                                                                'Pass' : 'Fail'
                                                        }}</span>
                                            </h3>
                                        </span>
                                        <div class="monitor_Branding_info">
                                            <div :class="monitorData.serviceName === 'Domain Blacklist' ? 'sup_Branding_info_section domain_blocklist' : 'sup_Branding_info_section' ">
                                                <!-- <div class="sup_section_title">
                                                    <h4>&nbsp;</h4>
                                                </div> -->
                                                <ul>
                                                    <li class="align-item"
                                                        v-for='(value, index) in monitorData.serviceData' :key='index'>
                                                        <div :class="monitorData.serviceName === 'Domain Blacklist' ?  'domain_blocklist_wrapper'  : monitorData.serviceName === 'SSL Certificate Validity' ? 'other_block_list': '' ">
                                                        <span  v-if="monitorData.serviceName === 'Domain Blacklist'">
                                                        <i  :class="value === 'clean' ? 'pi pi-check-circle greenTick' : value === 'unrated' ? 'pi pi-exclamation-triangle unratedSign' : 'pi pi-exclamation-circle suspiciousCircel'"></i></span>
                                                            <strong :style="{width : (monitorData.serviceName === 'Domain Blacklist' || monitorData.serviceName === 'SSL Certificate Validity') ? '' : 'auto' }" >{{ monitorData.serviceName === 'Domain Blacklist' ? index : camelToWords(index) }}</strong>
                                                            <span v-if="monitorData.serviceName !== 'Domain Blacklist'">
                                                            : {{ value }}
                                                            </span>
                                                        </div>
                                                        
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                     <!-- <TabPanel header="Feature Usage">
                        <div class="up_website_pageviews p-d-flex">
                            <div class="p-col-6">
                                <div class="up_website_pageviews_card">
                                    <h4>Month Over Month - Widget Pageviews</h4>
                                </div>
                            </div>
                            <div class="p-col-6">
                                <div class="up_website_pageviews_card">
                                    <h4>Overall Feature Usage</h4>
                                </div>
                            </div>
                        </div>
                    </TabPanel> -->
                    
                </TabView>
            </div>
        </Dialog>
        <!--- View Website Details Modal --->
        <!--- Cancel Website Modal --->
        <Dialog header="Cancel Website" v-model:visible="websiteModal.cancelWebsite" class="cancel_website_modal" :style="{width: '500px'}" :modal="true">
            <div class="up_cancel_website">
                <p><em>Please Provide Us The Following Information</em></p>
                <form @submit.prevent="cancelWebsite(websiteModal.cancelWebsiteUuid)" class="p-mt-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12">
                            <label for="cancelReson">Reason</label>
                            <Dropdown v-model="websiteCancel.reason" :options="websiteCancel.resonType" optionLabel="name" placeholder="Please select reason" />
                            <small class="p-invalid text-danger" v-if="websiteCancel.reasonEmpty">Please Select a reason.</small>
                        </div>
                        <div class="p-field p-col-12">
                            <label for="websiteMessage">Message</label>
                            <Textarea id="websiteMessage" row="3" v-model="websiteCancel.message" />
                            <small class="p-invalid text-danger" v-if="websiteCancel.messageEmpty">Message should contains atleast 50 and not more than 200 characters.</small>
                        </div>
                        <div class="p-field p-col-12">
                            <Button type="submit" label="Send Request" class="p-button-primary up_btn_wrap" />
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
        <!--- Cancel Website Modal --->
        <!--- Start New scan Modal --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{ width: '350px' }"
        :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text"
                    autofocus />
                <Button label="Yes" icon="pi pi-check"
                    @click="startScan(confirmationModal.modaldata.data), confirmationModal.modalState = false"
                    class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Start New scan Modal --->
        <!-- All actions notification -->
        <Toast position="top-right" />
        <!-- All actions notification -->
        <!-- Delete restore confimation dialog Start -->
        <ConfirmDialog></ConfirmDialog>
        <!-- Delete restore confimation dialog End -->
</template>

<script>
import { userPortalWebsite, userPortalWebsiteOne, userPortalWebsiteScript, 
userPortalWebsiteService, userPortalInvoice, userPortalReGenerateLighthouse,
userPortalScanedById, userPortalDownloadReport, userPortalWebsiteScan } from '../../../../config/appUrls';
import { apiKey, imageUrlFrontEnd, iconUrl, logoUrl } from "../../../../config/constant";
import customerLogout from '../../../../helper/userLogout/customerLogout';
import { usFormatDate, camelToWords, formatDate } from '../../../../helper/utility/utility';
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount, ref } from 'vue';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
const FileSaver = require('file-saver');

export default {
    name: 'ManageExistingSite',
    setup() {
        //Application store call
        const store = useStore();

        //Toast Message call
        const toast = useToast();

        //Confirm screen call
        const confirm = useConfirm();

        const scanNewWebsite = ref(false);
        const seletedItem = reactive({
            clicked : false,
            _id : ''
        })

        const newScan = reactive({
            selectedWebsite: '',
            websiteError: false,
            scanProgress: false
        });
        const rescanSelect = reactive({
            id: '',
            scan: false
        });

        const confirmationModal = reactive({
            modalState: false,
            modaldata: ''
        })

        /**
         * 
         * Widget loading script initating state breakdown. 
         * Follow this to create a script string otherwise vue won't allow us to add more script inside script
         * 
         */
        const scriptValue = ref({
            firstPart: '<script type="text/javascript" src="https://app.dev.truabilities.com/release/truabilities.js?widgetkey=',
            LastPart: '"></',
            LastEndPart: 'script>'
        })

        const progressReport = reactive ({
            loadingDownload: false,
            error: null,
            contrastErrors: null,
            alerts: null,
            features: null,
            structuralElements: null,
            aria: null,
            totalViolation: null,
            uuid: null,
            loading: false
        });

        //Website initial state
        const allWebsiteData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            search: null,
            sortBy: 'both',
            invoice: null,
            diplayClass : null,
            diplaySpinnerClass : 'none'
        });

        const monitorData = reactive({
            data: null,
            status: null,
            loading: false,
            isShow: false,
            serviceName: null,
            isQualified: true,
            serviceData: null
        });

        //Website modal initiating states
        const websiteModal = reactive({
            viewWebsite: false,
            viewWebsiteData: '',
            cancelWebsite: false,
            cancelWebsiteUuid: '',
            serviceType : '',
            brandingData: '',
            gdprData : ''
        });

        //Website cancel time fields state
        const websiteCancel = reactive({
            resonType: [
                {name: 'Price is too high', value: 'Price is too high'},
                {name: 'Do not want it now', value: 'Do not want it now'},
                {name: 'We are using for testing', value: 'We are using for testing'},
                {name: 'Other Reason', value: 'Other Reason'}
            ],
            reason: '',
            reasonEmpty: false,
            message: '',
            messageEmpty: false
        })

        onBeforeMount(async() => {
            fetchAllWebsite('rescan');
            setTimeout(() => { fetchAllWebsite() }, 90000);
        });

        /**
         * 
         * Fetch all website form server
         * userPortalWebsite - variable to api url
         * fetching all kind of data with 'all' status
         * customerLogout() - logout if unauthorized user
         * 
         */
        const fetchAllWebsite = async (load) =>{
            if(load === 'resetFilters'){
                allWebsiteData.search = null;
            }
            
            try{
                if(load !== 'rescan'){
                    allWebsiteData.loading = !allWebsiteData.loading;
                }
                
                const response = await axios.get( userPortalWebsite, {
                    params:{
                        status: 'all',
                        search: allWebsiteData.search
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allWebsiteData.data = response.data.data.website.filter(x=>x.deletedAt === null);
                allWebsiteData.total = response.data.data.total;
                if(load !== 'rescan'){
                    allWebsiteData.loading = !allWebsiteData.loading;
                }
                
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        /**
         * 
         * Specific website details call in new window
         * 'slotData' paremeter provieds id 
         * 
         */
        let _result;

        const viewWebsiteById = async(slotData) => {
            try{
                const response = await axios.get( userPortalWebsiteOne, {
                    params: {
                        uuid: slotData.uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                websiteModal.viewWebsiteData = response.data.data[0];
                websiteModal.brandingData = response.data.data;
                progressReport.uuid = response.data.data[0].Websiterealtimescanreport === null ? null : response.data.data[0].Websiterealtimescanreport.uuid;
                if (response.data.data[0].Websitebranding) {
                    _result = response.data.data[0].Websitebranding.gdprText && response.data.data[0].Websitebranding.gdprText !== "{}"? 
                    // Object.values(JSON.parse(response.data.data[0].Websitebranding.gdprText)) :
                    Object.values(response.data.data[0].Websitebranding.gdprText) :
                    'NA';
                    websiteModal.gdprData = _result !== 'NA' ? _result[0] : 'NA';
                } else {
                    websiteModal.gdprData = 'NA';
                }                
                websiteModal.viewWebsite = true;
                websiteModal.serviceType = websiteModal.viewWebsiteData.serviceType? websiteModal.viewWebsiteData.serviceType.split("_"): "";
                fetchInvoice(slotData.uuid);

                let _sslDt;
                if (websiteModal.viewWebsiteData.Websitemonitoring !== null) {
                    for (let i = 0; i < websiteModal.viewWebsiteData.Websitemonitoring.length; i++) {
                    const element = websiteModal.viewWebsiteData.Websitemonitoring[i].serviceName;
                        if(element === 'SSL Certificate Validity'){
                            _sslDt = websiteModal.viewWebsiteData.Websitemonitoring[i];
                        }else{
                            break;
                        }
                    }
                    openBox(_sslDt,0);
                }
                getHitsGraphData(slotData);
            } catch(err){
                console.log('error', err);
            }
        }

        const fetchInvoice = async(uuid) => {
            try{
                const response = await axios.get( userPortalInvoice, {
                    params: {
                        websiteUuid : uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allWebsiteData.invoice = response.data.data.invoice                    
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }

        }

        const downloadScript = async(slotData) => {
            const response = await axios.get( userPortalWebsiteScript, {
                responseType: 'arraybuffer',
                params: {
                    uuid : slotData.uuid
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }, 
            });
            if(response.status === 200) {
                const dirtyFileName = response.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                var fileName = dirtyFileName.match(regex)[3];
                var blob = new Blob([response.data], { type: 'application/pdf' });
                FileSaver(blob, fileName);
            }
        }

        const confirmModal = (slotData, load) =>{
            if(load === 'cancel'){
                websiteModal.cancelWebsite = true;
                websiteModal.cancelWebsiteUuid = slotData.uuid;
            } else {
                    confirm.require({
                    message: 'Are you sure about this ?',
                    header: 'Delete Permanently',
                    icon: 'pi pi-trash',
                    acceptLabel: 'Confirm',
                    rejectLabel: 'Cancel',
                    acceptClass: 'p-button-primary',
                    accept: async() => {
                        try {
                            const response = await axios.delete( userPortalWebsite, {
                                params: {
                                    uuid : slotData.uuid
                                },
                                headers: {
                                    apiKey: apiKey,
                                    token: store.getters.adminAuthToken
                                }, 
                            });
                            if(response.data.status === 200){
                                toast.add({severity:'info', summary:'Deleted', detail:'Website deleted Successfully.', life: 3000});
                                fetchAllWebsite();
                            }
                        } catch (error) {
                            toast.add({severity:'error', summary:'Error', detail:error.response.data.message, life: 3000});
                            fetchAllWebsite(); 
                        }
                    }
                });
            }
        }

        const cancelWebsite = async(uuid) => {

            if(websiteCancel.reason === '') {
                websiteCancel.reasonEmpty = true;
            } else {
                websiteCancel.reasonEmpty = false;
            }

            if(websiteCancel.message.length < 50 || websiteCancel.message.length > 200) {
                websiteCancel.messageEmpty = true;
            } else {
                websiteCancel.messageEmpty = false;
            }

            if ( !websiteCancel.reasonEmpty && !websiteCancel.messageEmpty ) {
                try{
                    const response = await axios.patch( userPortalWebsite, {
                        reason: websiteCancel.reason.value,
                        message: websiteCancel.message
                    }, {
                        params: {
                            uuid : uuid
                        },
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }, 
                    });
                    if (response.data.status === 200) {
                        websiteModal.cancelWebsite = false;
                        websiteCancel.reason = '';
                        websiteCancel.reasmessageon = '';
                        toast.add({severity:'success', summary: 'Cancel website', detail: "Cancel website subscription request has successfully made. An associate will get in touch with you shortly."});
                        fetchAllWebsite();
                    }
                } catch(err) {
                    websiteModal.cancelWebsite = false;
                    toast.add({severity:'error', summary: 'Cancel website', detail: err.response.data.message});
                }
            }
        }

        const copyScript = (e) => {
            let code = e.target.nextSibling.value;
            navigator.clipboard.writeText(code).then(function() {
                toast.add({severity:'success', summary:'Script copied!', detail:'Script copied to clipboard.', life: 2000});
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        }

        const deleteService = async(slotData, name) => {
            try{
                const options = {
                    method: 'delete',
                    url: userPortalWebsiteService,
                    params: {
                        uuid: slotData.uuid
                    },
                    data: JSON.stringify({
                        serviceType: name
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllWebsite();
                toast.add({severity:'error', summary:'Deleted!', detail: name + ' service deleted successfully.', life: 3000});
            } catch(err){
                console.log(err);
            }
        }

        const reGenerateLightHouse = async(siteUrl) => {
            try{
                rescanSelect.id = siteUrl;
                rescanSelect.scan = true;
                const response = await axios.post( userPortalReGenerateLighthouse, {
                    url : siteUrl
                }, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.customerPortalToken
                    },
                });
                if (response.status === 200) {
                    toast.add({severity:'success', summary:'Updated!', detail: ' Lighthouse report updated successfully.', life: 3000});
                    fetchAllWebsite('rescan');
                    rescanSelect.scan = false;
                }
                // allWebsiteData.loading = !allWebsiteData.loading;
            } catch(err){
                console.log(err);
            }
        }

        const fetchScanReport = async() =>{
            try{
                if (progressReport.uuid !== null) {
                progressReport.loading = !progressReport.loading;
                const response = await axios.get( userPortalScanedById, {
                    params:{
                        uuid: progressReport.uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                progressReport.error = response.data.data.errors;
                progressReport.contrastErrors = response.data.data.contrastErrors;
                progressReport.alerts = response.data.data.alerts;
                progressReport.features = response.data.data.features;
                progressReport.structuralElements = response.data.data.structuralElements;
                progressReport.aria = response.data.data.arias;
                progressReport.loading = !progressReport.loading;
                }
            } catch(err){
                if(err.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const downloadReport = async() => {
            progressReport.loadingDownload = !progressReport.loadingDownload;
            const response = await axios.get( userPortalDownloadReport, {
                responseType: 'arraybuffer',
                params: {
                    uuid : progressReport.uuid
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }, 
            });
            if(response.status === 200) {
                const dirtyFileName = response.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                var fileName = dirtyFileName.match(regex)[3];
                var blob = new Blob([response.data], { type: 'application/pdf' });
                FileSaver(blob, fileName);
                progressReport.loadingDownload = !progressReport.loadingDownload;
            }
        }

        let simplevar;
        const openBox = async (val, i) => {
            monitorData.isShow = true;
            monitorData.isActive = true;
            monitorData.serviceName = val.serviceName;
            monitorData.isQualified = val.isQualified;
            seletedItem._id = 'monitor_box'+i;
            seletedItem.clicked = true;
            simplevar = val.serviceData;
            if (monitorData.serviceName === 'SSL Certificate Validity') {
                let serviceArr = {};
                 for (const key in simplevar) {
                    if (Object.hasOwnProperty.call(simplevar, key)) {
                        const element = simplevar[key];
                        if(key === 'expiresIn'){
                            serviceArr[key] = element+' Days';
                        }else if(key === 'validTo'){
                            serviceArr[key] = usFormatDate(element);
                        }else if(key === 'validFrom'){
                            serviceArr[key] = usFormatDate(element);
                        }else if(key === 'subjectAltName'){
                            serviceArr[key] = element.replace(/,/g, " ");
                        }else{
                            serviceArr[key] = element;
                        }
                        
                    }
                }
                delete serviceArr.issuer;
                delete serviceArr.status;
                delete serviceArr.pemEncoded;
                delete serviceArr.subject;

                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Yandex Safe Browsing') {
                let serviceArr = {};
                if (simplevar.result === "clean") {
                    serviceArr['Your site analysis by Yandex safe Browsing is '] = 'Secure'
                } else if (simplevar.result === 'unrated') {
                    serviceArr['Your site analysis by Yandex safe Browsing is '] = 'Unrated'
                } else {
                    serviceArr['Your site analysis by Yandex safe Browsing is '] = 'Harmful'
                }
                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Sucuri Site Check') {
                let serviceArr = {};
                if (simplevar.result === "clean") {
                    serviceArr['Your site analysis by Sucuri Site Check is '] = 'Secure'
                } else if (simplevar.result === 'unrated') {
                    serviceArr['Your site analysis by Sucuri Site Check is '] = 'Unrated'
                } else {
                    serviceArr['Your site analysis by Sucuri Site Check is '] = 'Harmful'
                }
                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Domain Blacklist') {
                let serviceArr = {};
                for (const key in simplevar) {
                    if (Object.hasOwnProperty.call(simplevar, key)) {
                        const element = simplevar[key];
                        serviceArr[key] = element.result;
                    }
                }

                monitorData.serviceData = serviceArr;
            }
            else if (monitorData.serviceName === 'Google Safe Browsing') {
                let serviceArr = {};
                if (Object.values(simplevar) === true) {
                    serviceArr['Your site ' + Object.keys(simplevar) + ' analysis by Google Safe Browsing is '] = 'Unsafe'
                } else {
                    serviceArr['Your site analysis by Google Safe Browsing is '] = 'Safe'
                }
                monitorData.serviceData = serviceArr;
            } else {
                let serviceArr = {};
                serviceArr['Your site Page Speed Score is '] = simplevar.score * 100;
                monitorData.serviceData = serviceArr;
            }

        }

        function dateDiff(startingDate, endingDate) {
            var startDate = new Date(new Date(startingDate).toISOString().substring(0, 10));
            if (!endingDate) {
                endingDate = new Date().toISOString().substring(0, 10); // need date in YYYY-MM-DD format
            }
            var endDate = new Date(endingDate);
            if (startDate > endDate) {
                var swap = startDate;
                startDate = endDate;
                endDate = swap;
            }
            var startYear = startDate.getFullYear();
            var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
            var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            var yearDiff = endDate.getFullYear() - startYear;
            var monthDiff = endDate.getMonth() - startDate.getMonth();
            if (monthDiff < 0) {
                yearDiff--;
                monthDiff += 12;
            }
            var dayDiff = endDate.getDate() - startDate.getDate();
            if (dayDiff < 0) {
                if (monthDiff > 0) {
                    monthDiff--;
                } else {
                    yearDiff--;
                    monthDiff = 11;
                }
                dayDiff += daysInMonth[startDate.getMonth()];
            }
            if (yearDiff === 0 && monthDiff > 0) {
                return monthDiff + (monthDiff > 1 ? ' Months ' : ' Month ') + dayDiff + (dayDiff > 1 ? ' Days ago ' : ' Day ago ');
            } else if (yearDiff === 0 && monthDiff === 0) {
                return dayDiff + (dayDiff > 1 ? ' Days ago' : ' Day ago ');
            } else {
                return yearDiff + (yearDiff > 1 ? ' Years ' : ' Year ') + monthDiff + (monthDiff > 1 ? ' Months ' : ' Month ') + dayDiff + (dayDiff > 1 ? ' Days ago ' : ' Day ago ');
            }
        }

        const startScan = async(siteUrl) => {
            // if (newScan.selectedWebsite === '') {
            //     newScan.websiteError = true;
            // } else {
                newScan.websiteError = false;
                try{
                    newScan.scanProgress = true;
                    const response = await axios.post( userPortalWebsiteScan, {
                        websiteUrl: siteUrl
                    }, {
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if (response.data.status === 200) {
                        newScan.scanProgress = false;
                        scanNewWebsite.value = false;
                        toast.add({severity:'success', summary:'Success', detail:response.data.data, life: 3000});
                        fetchAllWebsite();
                    } 
                    if(response.status === 400){
                        toast.add({severity:'error', summary:'Exists!', detail:response.message, life: 3000});
                    }
                } catch (err) {
                    console.error(err);
                }
            // }
        }

        const confimationModal = (sloteData) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData
            }
        }
        
        const hitsGraphData = reactive({
            type: '1',
            year: (new Date()).getFullYear(),
            labels: [],
            datasets: [
                {
                    label: 'Pageviews',
                    data: [],
                    fill: true,
                    borderColor: '#FFA726',
                    tension: .4,
                    backgroundColor: 'rgba(255,167,38,0.2)'
                }
            ],
            available: false
        });

        const getHitsGraphData = async function(data) {
            try {
                // resetting previous values
                hitsGraphData.labels = [];
                hitsGraphData.datasets[0].data = [];
                hitsGraphData.available = false;

                let today = new Date();
                const response = await axios({
                    method: 'GET',
                    url: userPortalWebsite + '/hits-graph',
                    params: {
                        type: '1',
                        year: today.getFullYear(),
                        uuid: data.uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                console.log(response);
                if (response.data.status === 200) {
                    hitsGraphData.available = true;
                    hitsGraphData.labels = response.data.data.labels;
                    hitsGraphData.datasets[0].data = response.data.data.data;
                }
            } catch(err) {
                hitsGraphData.available = false;
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        return{
            scriptValue,
            imageUrlFrontEnd,
            allWebsiteData,
            websiteModal,
            websiteCancel,
            iconUrl,
            logoUrl,
            monitorData,
            startScan,
            confimationModal,
            dateDiff,
            openBox,
            fetchAllWebsite,
            usFormatDate,
            formatDate,
            viewWebsiteById,
            downloadScript,
            confirmModal,
            cancelWebsite,
            copyScript,
            deleteService,
            reGenerateLightHouse,
            progressReport,
            fetchScanReport,
            downloadReport,
            confirmationModal,
            scanNewWebsite,
            newScan,
            basicData: {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'My First dataset',
						backgroundColor: '#42A5F5',
						data: [65, 59, 80, 81, 56, 55, 40]
					},
					{
						label: 'My Second dataset',
						backgroundColor: '#9CCC65',
						data: [28, 48, 40, 19, 86, 27, 90]
					}
				]
			},
            hitsGraphData,
            getHitsGraphData,
            multiAxisData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [{
                    label: 'Customers',
                    fill: true,
                    borderColor: '#00bb7e',
                    yAxisID: 'y1',
                    tension: .4,
                    data: [28, 48, 40, 19, 86, 27, 90],
                    backgroundColor: 'rgba(0,219,182,0.2)'
                }]
            },
            lineStylesData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'Pageviews',
                        data: [12, 51, 62, 33, 21, 62, 45],
                        fill: true,
                        borderColor: '#FFA726',
                        tension: .4,
                        backgroundColor: 'rgba(255,167,38,0.2)'
                    }
                ]
            },
            multiAxisOptions:{
                stacked: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            drawOnChartArea: false,
                            color: '#ebedef'
                        }
                    }
                }
            },
            selectedCity: null,
            camelToWords,
            cities: [
                {name: 'This Week'},
                {name: 'Previous Week'},
                {name: 'Last Month'}
            ],
            rescanSelect,
            seletedItem
        }
    }
}
</script>


<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/ManageBranding/ManageBranding';
@import '../../../../assets/style/component/userportal/pages/ManageExistingSite/ManageExistingSite';
@import '../../../../assets/style/component/userportal/pages/Monitor/monitor.css';

.view_inv {
    background-color: transparent;
    color: #2196F3;
    border: 1px solid;
    float: right;
    padding: 8px 8px;
    margin-top: 56px;
    margin-left: 12px;
}

.month_wise_cart_view_unavailable {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  opacity: 60%;
}

</style>